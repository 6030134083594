// main:style.less

header {
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    position: fixed;
    padding: 0 1rem;
    background-color: var(--header-bg-color);

    .top-wrapper {
        .flex-display();
        .justify-content(space-between);
        .align-items(center);
        width: 100%;
        position: relative;
        margin: 0.5rem 0;
        gap: 0.5rem;

        .link-wrapper {
            .flex-display();
        }

        .link {
            .flex-display();
            .align-items(center);
            padding: 0 0.5rem;
            color: var(--header-link-color);
            background-color: var(--header-link-bg-color);
            margin-right: 0.25rem;
            height: 2rem;
            line-height: 1rem;
            font-size: 0.8125rem;
            .rounded(0.25rem);

            a {
                color: inherit;
            }

            &:last-child {
                margin-right: 0;
            }

            &.upload_button {
                position: relative;
                z-index: 1000;

                a {
                    .opacity(0.5);
                }

                span.tooltip {
                    position: absolute;
                    width: 21.875rem;
                    max-width: 80vw;
                    background-color: var(--header-tooltip-bg-color);
                    z-index: 1000;
                    padding: 1.25rem 0.625rem;
                    .rounded(0.3125rem);
                    display: none;
                    right: 0;
                    top: 2.125rem;
                    line-height: 1.3;
                    color: var(--header-tooltip-text-color);
                }

                &:hover {
                    a {
                        .opacity(0.2);
                    }

                    span.tooltip {
                        display: inline;
                    }
                }
            }
        }

        .logo {
            a {
                height: 1.875rem;
                max-width: 9.125rem;
                display: block;
                border-top-width: 0;
                border-bottom-width: 0;
                overflow: hidden;
            }
        }
    }

    .search {
        position: relative;
        margin: 0.5rem 0;

        form input {
            height: 2.375rem;
            width: 100%;
            background-color: var(--searchbar-bg-color);
            border: 0 solid var(--searchbar-dark-border-color);
            font-size: @input-font-size;
            font-weight: 400;
            text-indent: 0.9375rem;
            line-height: 1.25rem;
            color: var(--searchbar-text-color);
            padding-right: 2.375rem;
            .rounded(0.3125rem);

            &::placeholder {
                color: rgba(var(--searchbar-text-color), 0.6);
            }

            &:focus {
                color: var(--searchbar-focus-text-color);
            }
        }

        form button {
            position: absolute;
            right: 0.125rem;
            height: 2.375rem;
            width: 2.375rem;
            line-height: 2.375rem;
            .rounded(0.1875rem);
            background-color: transparent;

            .i_svg {
                fill: var(--searchbar-icon-color);
                font-size: 1.2em;
                opacity: 0.6;
            }
        }
    }
}

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
}

.headroom--pinned,
.headroom--unpinned .auto_holder {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
}

.headroom--unpinned,
.headroom--unpinned .auto_holder {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
}

.header_tabs {
    a {
        display: inline-block;
        width: 33.333%;
        text-align: center;
        border: 0.0625rem solid @ebony-clay;
        border-top: 0;
        padding: 0.1875rem 0;
        color: @gothic;
        font-weight: bold;
    }
}

.autocomplete-suggestions {
    background-color: @heavy-metal !important;
    margin-top: 0.1875rem;
    color: @white;

    .autocomplete-suggestion {
        padding: 0.625rem 0.3125rem 0.625rem 0.3125rem;
        cursor: pointer;
        display: block;
        overflow: hidden;
        font-size: 1.3em;
    }

    .autocomplete-selected,
    .autocomplete-suggestion:hover {
        background-color: fade(@moccaccino, 30) !important;
    }

    strong {
        font-weight: normal;
        color: @silver-chalice;
    }
}
