// main:style.less

.auth-form {
    .title {
        color: var(--input-color);
        background: none;
        padding: 0;
        margin-bottom: 0.5rem;
        text-align: center;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 2rem;
    }

    .features {
        .flex-display();
        .align-items(center);
        .justify-content(space-around);
        background-color: var(--signup-features-bg-color);
        height: 2.5rem;
        .rounded(0.25rem);

        span {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: var(--signup-feature-text-color);
        }

        svg {
            fill: var(--signup-icon-color);
            margin-right: 0.5rem;

            .i_new-ui-hot {
                height: 0.625rem;
                width: 0.5rem;
            }

            .i_new-ui-visible-outline {
                height: 0.4375rem;
                width: 0.625rem;
            }
        }
    }

    ul {
        li {
            clear: both;
            margin: 4px 0;
            padding: 5px 0;

            .auth_field {
                display: flex;
                flex-direction: column;
                gap: 14px;
            }
        }
    }

    .action {
        .primary-button(@size: lg);
        width: 100%;
    }

    .secondary-action {
        .secondary-button();
    }
}

@media screen and (min-width: 375px) {
    .auth-form {
        .features {
            span {
                font-size: 0.875rem;
                color: var(--signup-feature-text-color);
            }
        }
    }
}
