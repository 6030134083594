// main:style.less

#search_v2 {
    .channel_result {
        overflow: hidden;

        .ps {
            display: inline-block;
            width: 100%;
            text-align: left;
            color: @front-l4;
            margin: 5px 0;
            line-height: 40px;

            img {
                float: left;
                width: 50px;
                margin: 0 10px;
                .rounded(5px);
            }
        }
    }

    .tag_list {
        .tag-list(a);
    }
}

#search_empty {
    text-align: center;
    font-size: 1rem;
    line-height: 1.25rem;
    color: @search-empty-color;
    .space-vertical();

    .ft-button {
        display: inline-block;
    }
}
