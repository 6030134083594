// main:style.less

.form-holder {
    overflow: hidden;
    text-align: left !important;

    h2 {
        font-size: 1.15em;
        background-color: @back-d4;
        padding: 5px;
    }

    ul.country_list {
        margin: 5px 0;

        li {
            display: inline-block;
            cursor: pointer;
            width: 18%;
            text-align: center;
            .rounded(5px);

            &:hover {
                background-color: @back;
            }

            img {
                width: 32px;
                height: 20px;
                display: inline-block;
                margin: 2px 0;
                .rounded(3px);
                cursor: pointer;

                &.ww {
                    width: auto;
                }
            }
        }
    }

    ul {
        li {
            clear: both;
            margin: 4px 0;
            padding: 5px 0;

            label {
                color: @back;
            }

            label.main {
                display: block;
                float: left;
                width: 100%;
                padding-left: 5px;
                height: 25px;
                line-height: 25px;
                color: @front-l4;
            }

            p.desc {
                color: @back-l3;
                font-size: 0.9em;
                clear: both;
                padding: 2px 0 5px 5px;
            }

            p.errors {
                clear: both;
                overflow: hidden;
                color: @front-l4;
                padding: 10px 0 10px 0;
            }

            input[type='radio'] {
                display: block;
                margin: 7px 6px 0 4px;
                float: left;
            }

            input[type='checkbox'] {
                display: block;
                margin: 7px 6px 0 4px;
                float: left;
            }

            .text-field {
                height: 40px;
                line-height: 40px;
                width: 100%;
                border: 1px solid @back;
                display: block;
                float: left;
                text-indent: 5px;
                font-size: @input-font-size;
                background-color: @back-d4;
                color: @text-l1;

                &:focus {
                    background-color: @back-d3 !important;
                    border: 1px solid @front-d4;
                }

                &:hover {
                    background-color: @back-d3;
                }
            }

            .text-field-small {
                width: 100px;
            }

            .text-field-medium {
                width: 150px;
            }

            .textarea-field {
                height: 100px;
            }
        }
    }
}

.big-text-black {
    background-color: @back-d4;
    width: 100%;
    max-width: 400px;
    height: 40px;
    color: @front-l4;
    padding: 4px;
    font-size: 1.3em;

    .bordered(#222,#111,#111,#333);
    .drop-shadow();
}
