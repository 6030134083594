// main:style.less

#bottom_nav {
    background-color: @footer-bg-color;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom, 0);
    left: 0;
    z-index: 1000;
    transition: height 500ms;
    color: @footer-color;
    overflow: auto;

    .top_nav {
        padding: 0 0.5rem;
        gap: 0.5rem;
        position: sticky;
        top: 0;
        background-color: @footer-bg-color;
        z-index: 1;
        .flex-display();
        .justify-content(space-between);
        .align-items(center);

        a {
            display: block;
            text-align: center;
            color: @footer-nav-link-color;
            position: relative;
            padding: 0.5rem 0;
            .flex-basis(20%);
            font-size: 0.75rem;
            line-height: 0.625rem;

            .icon {
                width: 1.25rem;
                height: 1.25rem;
                margin: 0 auto 0.125rem;
                .flex-display();
                .justify-content(center);
                .align-items(center);

                .i_svg {
                    fill: @footer-nav-icon-color;
                    top: 0;
                    font-size: 1rem;
                }
            }

            &.more_menu {
                .icon-close {
                    display: none;

                    .i_svg {
                        font-size: 0.75rem;
                    }
                }

                .is_open& {
                    .icon-close {
                        .flex-display();
                    }

                    .icon-open {
                        display: none;
                    }
                }
            }
        }
    }

    .bottom_nav {
        display: none;

        .highlighted-wrapper {
            background-color: @footer-highlighted-bg-color;
            padding: 1rem;

            .guest-wrapper {
                h4 {
                    font-size: 1.125rem;
                    font-weight: 700;
                    line-height: 1.5rem;
                    text-align: center;
                }

                p {
                    margin-top: 0.25rem;
                    font-size: 0.875rem;
                    text-align: center;
                    line-height: 1.25rem;
                    color: @footer-highlighted-text-color;

                    span {
                        color: @footer-highlighted-text-highlighted-color;
                    }
                }

                .benefits {
                    margin-top: 1rem;
                    .space-vertical(0.5rem);

                    li {
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        font-weight: 700;
                        color: @footer-benefits-text-color;

                        .i_svg {
                            width: 1.25rem;
                            height: 1.25rem;
                            padding: 0.0625rem;
                            top: 0.3125rem;
                            margin-right: 0.25rem;
                            color: @footer-benefits-icon-color;
                        }
                    }
                }

                nav {
                    margin-top: 1rem;
                    .flex-display();
                    .flex-direction(column);

                    a {
                        font-size: 0.875rem;
                        padding: 0 1rem;
                        height: 2.5rem;
                        .flex-display();
                        .justify-content(center);
                        .align-items(center);
                        .rounded(0.25rem);

                        .i_svg {
                            top: 0;
                            margin-right: 8px;
                        }
                    }

                    .login_title {
                        justify-content: center;
                        font-size: 1.125rem;
                        display: flex;
                        border-top: 1px solid @footer-login-separator-color;
                        padding-top: 1.125rem;
                    }

                    .users_login_link {
                        .flex-display();
                        gap: 0.25rem;
                    }
                }
            }
        }

        .further-links-wrapper {
            padding: 1rem;
        }

        .bottom-btn {
            padding: 0.5rem 0.75rem;
            background-color: @footer-bottom-btn-bg-color;
            color: @footer-bottom-btn-color;
            font-size: 0.875rem;
            line-height: 1.5rem;
            overflow: hidden;
            gap: 0.5rem;
            .flex-display();
            .align-items(center);
            .rounded(0.5rem);

            .icon {
                width: 1.25rem;
                height: 1.25rem;
                .flex-display();
                .justify-content(center);
                .align-items(center);
                .flex-shrink(0);

                .i_svg {
                    fill: @footer-bottom-btn-icon-color;
                    top: 0;
                    font-size: 1rem;
                }
            }

            .text {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .badge {
                .rounded(0.125rem);
                padding: 0.25rem;
                font-size: 0.75rem;
                line-height: 0.875rem;
                font-weight: 700;
                background-color: @footer-bottom-btn-badge-bg-color;
                color: @footer-bottom-btn-badge-color;
                margin-left: auto;
                min-width: 1.25rem;
                text-align: center;
            }
        }

        .main-links {
            padding-top: 1rem;
            background-color: @footer-highlighted-bg-color;

            &.inside-further-links {
                padding: 0;
                background-color: transparent;
            }
        }

        .main-links,
        .user-menu-links {
            .flex-display();
            .flex-wrap(wrap);
            gap: 0.5rem;

            .bottom-btn {
                .flex-basis(calc(50% - 0.25rem));
            }
        }

        .user-menu-links {
            margin-top: 1rem;
        }

        .footer-links {
            .flex-display();
            .justify-content(center);
            .flex-wrap(wrap);
            .column-gap(1rem);
            row-gap: 0.5rem;
            margin-top: 1rem;
            padding: 0 0.5rem;

            a {
                line-height: 1.5rem;
                font-size: 0.875rem;
                color: @footer-link-color;
            }
        }

        .setting {
            .flex-display();
            .justify-content(center);
            margin-top: 1rem;
            gap: 0.5rem;

            .bottom-btn {
                flex-basis: 33.333%;
                .justify-content(center);

                .icon {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0;

                    .i_svg {
                        font-size: 1.25rem;
                    }
                }
            }
        }

        .account_bar {
            .flex-display();
            .align-items(center);
            .justify-content(center);
            gap: 0.5rem;

            .avatar-wrapper {
                display: block;

                img {
                    width: 2.5rem;
                    height: 2.5rem;
                    object-fit: cover;
                    display: block;
                    .rounded(100%);
                }
            }

            .account-info {
                h6 {
                    font-size: 0.625rem;
                    line-height: 0.75rem;
                    font-weight: 400;
                }

                .u_name {
                    color: @footer-account-user-name-color;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    font-weight: 700;
                }
            }
        }
    }
}
