// main: style.less

.forgot {
  padding: 1rem;
  background-color: @forgot-bg-color;
  .rounded(0.25rem);

  ul {
    margin: 0 0 1rem;

    li {
      p.errors {
        text-align: center;
      }
    }
  }

  .ft-button {
    margin-left: auto;
    margin-right: auto;
  }
}
