// main:style.less

.live_h1 {
    span {
      float: right;
      color: @front-l4;

      .i_svg {
        fill: @front-d4;
      }
    }
}


#live {
  overflow: hidden;

  div.create_account {
    padding: 5px;
    margin: 10px;

    h1 {
      margin-bottom: 10px;
      color: @front-l4;
      text-align: center;
    }

    a {
      color: @front-l4;
    }

    p.errors {
      color: @front-l4;
    }

    ul.form {
      margin: 5px 0;
      background: @back-d1;
      padding: 5px;
      .rounded(5px);

      li {
        padding: 5px;
        text-align: center;

        label {
          display: block;
          cursor: pointer;
          padding-bottom: 5px;
          font-size: 1.2em;
          color: @text-l1;

          .i_svg {
            fill: @back-l4;
          }
        }

        input[type=text], input[type=password], select, textarea {
          width: 90%;
          height: 40px;
          line-height: 40px;
          background-color: @back-d4;
          border: 1px solid @front-d4;
          .rounded(5px);
          color: @text-l1;
          font-size: 1.5em;
          padding: 0 5px;
          text-align: center;
          color: @front-l4;
        }

        .text-field-small {
          width: 150px !important;
        }

        textarea {
          height: 150px;
          text-align: left;
        }


        &.t {
          color: @text-d1;
        }

        &.s {
          padding-top: 10px;
          margin-top: 10px;
          border-top: 1px solid @back-d4;
        }

        &.d {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid @back-d4;
            font-size: 1.3em;
            color: @front-l4;
            text-align: left;
        }

        &.l {
          padding: 10px 0;
          margin: 10px 0;
          border-top: 1px solid @back-d4;
          border-bottom: 1px solid @back-d4;
          font-size: 1.3em;
          color: @front-l4;
          background-color: @front-d4;

          a {
            color: @text-l1;
          }
        }

        &.form_zip_code {
          display: none;
        }
      }
    }
  }

  ul.results {
    overflow: hidden;
    li {
      float: left;
      margin: 0.5%;
      width: 15.5%;
      height: 240px;
      border-bottom: 1px;
      .text-shadow(@x-axis: 0, @y-axis: 2px, @blur: 2px, @alpha: 1);

      a.image {
        display: block;
        position: relative;

        img {
          width: 100%;
          .rounded(10px);
          .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 1px, @alpha: 0.6);
        }

        span {
          position: absolute;
          display: inline-block;
          padding: 5px;
          background-color: @back-d4;
          color: @text;
          .opacity(0.8);
          .rounded(5px);

          &.views {
            left: 2px;
            bottom: 5px;
          }

          &.videos {
            right: 2px;
            bottom: 5px;
          }
        }

        &:hover {
          span {
            background-color: @front-d4;
          }
        }
      }

      a.title {
        display: block;
        text-align: center;
        color: @text-l1;
        font-size: 1em;
        padding: 5px 0;
      }
    }
  }

  a.alpha {
    display: block;
    width: 40%;
    height: 50px;
    line-height: 30px;
    color: @text-l1;
    clear: both;
    overflow: hidden;
    text-align: center;
    margin: 20px auto;
    font-size: 1.5em;
  }
}

#live_performer {

  display: block;
  width: 100%;
  margin-top: 20px;

  #player_wrapper {
    margin: 0;
    display: block;
    clear: both;

		.player_loading {
		  display: block;
      width: 100%;
			.gradient(@color: @front-d4, @start: @back-d1, @stop: @back-d4);
      height: 300px;
      position: relative;

      .loading-icon img {
        height: 50px;
        display: block;
        margin: 15px auto 0 auto;
      }

			.loading-icon,
			.loading-icon::before,
			.loading-icon::after {
			  left: 50%;
			  top: 110px;
			  margin-left: -40px;
			  position: absolute;
			  background: @front;
			  width: 80px;
			  height: 80px;
			  border-radius: 30%;
			}

			.loading-icon::before {
        top: 0px;
			  content: "";
			  animation: bounce 1.5s infinite;
        animation-timing-function: ease-out;
			}
			.loading-icon::after {
        top: 0px;
			  content: "";
			  animation: bounce 1.5s -0.4s infinite;
        animation-timing-function: ease-out;
			}

		}

  }


  div.details {
    margin: 10px 0;
    font-size: 0.9em;

    .left {
      color: @text-l1;
      background-color: @back-d2;
      .rounded(5px);

      section {
        width: 100%;
        padding: 5px;
      }

      section.about {

        div.top {
          .flex-display(@display: flex);
          padding: 5px 0;

          > div {

            align-items: center;
            align-content: center;

            &.left {
              width: 130px;
              margin-right: 10px;
              .flex-grow(@grow: 0);
              .flex-shrink(@shrink: 0);
              .flex-basis(@width: 130px);

              img {
                .rounded(5px);
                display: inline-block;
                vertical-align: middle;
                margin-top: 10px;
              }
            }
          }

        }

        h1 {
          font-size: 1.5em;
          text-transform: uppercase;
          color: @text-l1;
          border-bottom: 1px solid @front;
          margin-bottom: 5px;
          height: 40px;
          line-height: 40px;
          background-color: transparent;

          img {
            width: 36px;
            display: inline-block;
            margin-top: 6px;
            .rounded(5px);
            border: 2px solid @back-d4;
          }

        }

        h2 {
          font-size: 1.1em;
          color: @text-l1;
          padding-bottom: 5px;
          background-color: transparent;
        }

        h3 {
          font-size: 1.05em;
          color: @text-l1;
          background-color: transparent;
        }

        ul > li {

          padding: 2px 0;
          font-size: 1.05em;

          span {
            color: @front-l4;
            display: block;
            padding: 5px 0 2px 0;
            font-size: 1.2em;
          }

          a {
            display: inline-block;
            .rounded(5px);
            background-color: @back;
            overflow: hidden;
            margin: 2px 0;
            color: @text-l1;
            padding: 7px;
            text-transform: capitalize;

            &:hover {
              background-color: @front-d2;
            }

            em {
              color: @text-d1;
              font-style: normal;
            }
          }
        }
      }

      section.features {

        font-size: 1.1em;

        h3 {
            color: @front-l4;
            display: block;
            padding: 5px 0 2px 0;
            font-size: 1.2em;
        }


        ul {

          li {
            line-height: 1.8em;
            color: @text-l1;

            span {
              color: @text-d1;
              padding-right: 10px;
              min-width: 140px;
              display: inline-block;
            }

            a {
              color: @text-l1;
              border-bottom: 1px solid @front-l4;

              &:hover {
                background-color: @front-d2;
              }
            }
          }
        }
      }
    }

    > div.right {


      h3 {
        font-size: 1.5em;
        text-transform: uppercase;
        color: @text-l1;
        border-bottom: 1px solid @front;
        margin-bottom: 5px;
        height: 44px;
        line-height: 44px;

      }

      section.media {

          overflow: hidden;
          clear: both;
          display: block;


          .thumbnails {

            margin-top: 5px;

            a {

              float: left;
              width: 32.8%;
              margin: 0.1%;
              position: relative;
              color: @text-l1;

              img {

    		          .drop-shadow(@x-axis: 0, @y-axis: 3px, @blur: 5px, @alpha: 0.8);
                  cursor: pointer;
                  .rounded(10px);
                  width: 100%;
                  border: 1px solid @back-d4;

                  &:hover{
                      border: 1px solid @front;
                  }
              }

              span {
                display: inline-block;
                position: absolute;
                right: 2px;
                bottom: 5px;
                background-color: @back-d4;
                font-size: 0.8em;
                padding: 1px 4px;
                .rounded(5px);
              }
            }


         }
        }
    }
    }

}


#live-album-remodal {
  width: 90% !important;
  max-width: 1500px;


  .live_photo_gallery_window {
    width: 100%;
    .flex-display(@display: flex);
    .flex-flow(row wrap);
    .justify-content(@justify: center);
    .align-items(@align: baseline);
    .align-content(@align: stretch);

    span {
      .flex(@columns: auto);
      background-color: @back-d4;
      margin: 3px;

      img {
          max-height: 700px;
          max-width: 700px;
          margin: 3px;
          object-fit: contain;
        }
    }

    video {
      width: 100%;
      max-height: 700px;
    }

  }

}

/* Landscape */
@media only screen and (min-width: 1366px) and (orientation: landscape) {



}

/* Landscape */
@media only screen and (min-width: 1024px) and (orientation: landscape) {

}

/*Portrait*/
@media only screen and (min-width: 1024px) and (orientation: portrait) {

}
