// main:style.less

.remodal {
    .flex-display();
    .align-items(flex-end);
    background: none;
    border: none;
    padding: 3rem 0 0;
    z-index: 400;
    color: var(--modal-text-color);

    h4 {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 700;
        color: var(--modal-title-color);
        padding: 0 1rem;

        .i_svg {
            fill: rgba(255, 255, 255, 0.5);
        }
    }

    .modal-inner-content {
        background-color: @modal-inner-content-bg-color;
        padding: 1rem 1rem 1.5rem 1rem;
        width: 100%;
        position: relative;
        .rounded(0.5rem 0.5rem 0 0);

        .remodal-close {
            width: 2rem;
            height: 2rem;
            right: 0;
            margin-top: 0;
            top: 0.25rem;
            .flex-display();
            .align-items(center);
            .justify-content(center);
        }
    }

    .remodal-close {
        top: 1rem;
        right: 1rem;
        font-size: 1rem;
        line-height: normal;
        height: 1rem;
        width: 1rem;

        .i_svg {
            position: static;
            fill: @signup-button-text-color;
            height: 0.75rem;
            width: 0.75rem;
        }
    }

    .common-remodal-wrapper {
        width: 100%;
    }

    .t {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
    }
}

.auth-remodal {
    .forms {
        width: 100%;
    }
}

[data-page='auth'] .logo {
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 12.5rem;
    height: 2.5rem;
    display: block;
}

#language-remodal,
#gender-remodal {
    .modal-inner-content {
        padding-top: 2.5rem;
    }
}

.download-remodal {
    .modal-inner-content > h4 {
        padding-top: 1rem;
    }

    .success {
        color: var(--success-color);
    }

    #captcha_holder {
        width: 19rem;
        margin: 0 auto;
    }

    .download-list {
        overflow: hidden;
        .space-vertical();

        .pl {
            display: none;
        }

        p > #icon > svg {
            top: 0.125rem;
        }

        .pl2 > #d-icon > svg {
            top: 0.125rem;
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    .download-promo {
        padding: 0 1.5rem;
        z-index: 100;
        background-color: var(--modal-inner-content-bg-color);
        display: none;
    }
}

.thumbnails-remodal {
    .thumbnails {
        margin-top: 20px;
    }
}

.flag-remodal {
    .modal-inner-content {
        .space-vertical();
    }

    .flag-wrapper {
        width: 100%;
    }

    .flag {
        text-align: left;
        .space-vertical(0.25rem);
    }

    .report {
        .opacity(0.5);
    }

    .report.active {
        .opacity(1);
    }
}
