.ttaa2v3 {
    display: block;

    a {
        background-image: linear-gradient(var(--misc-btn-bg-from-color), var(--misc-btn-bg-to-color));
        color: var(--misc-btn-color);
        .rounded(4px);
        text-align: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        padding: 0.5rem 2rem;
        line-height: 1.125rem;
        font-weight: 700;
        display: block;
        letter-spacing: 0.075rem;
    }
}

#video {
    .quality-items {
        > *:not(:last-child) {
            border-bottom: 1px solid @limed-spruce;
        }
    }

    .quality-item {
        display: flex;
        align-items: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
    }

    .quality-item-icon {
        font-size: 1rem;
        margin-right: 0.875rem;
        color: @mandy;

        &:not(.selected) {
            visibility: hidden;
        }
    }

    .quality-item-text {
        font-size: 0.875rem;
        color: @white;
    }
}

#video #video_container {
    display: none;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    margin: 0 auto;

    > #main_video_player,
    #vast_video_player {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
    }

    > #vast_video_player {
        display: block;
        z-index: 18;
        cursor: pointer !important;
        font-size: 0.9em !important;

        &.hidden {
            display: none;
        }
    }
}

#video #video_container {
    .vjs-poster {
        display: none;
    }

    .views.removed {
        display: none;
    }

    #main_video_player.video-js.vjs-theme-sb.video_container_removed {
        pointer-events: none;

        .vjs-big-play-button {
            pointer-events: none;
        }

        .vjs-controls-lower-container,
        .vjs-controls-upper-container {
            display: none;
        }

        .vjs-controls-middle-container {
            margin: auto;
        }
    }
}

#video {
    .video_removed {
        top: 50%;
        left: 50%;
        color: var(--video-removed-video-text-color);
        display: flex;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 15;
        background-color: var(--video-thumbnail-bg-color-rgba);
        border-radius: 0.25rem;
        padding: 1rem;
        white-space: nowrap;
    }

    span.i-play {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        padding: 0;
        margin: auto;
        .drop-shadow(@x-axis: 0, @y-axis: 3px, @blur: 10px, @alpha: 0.6);
        .rounded(5px);
        pointer-events: all;
        z-index: 50;
        background-color: var(--video-play-bg-color-rgba);
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 3.25rem;
        border: transparent;
        font-size: 3.5rem;
    }

    &:hover span.i-play {
        background-color: fade(@back-d4, 90);
    }

    span.i-play:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M18 11.86v.28c-.0008.2666-.1412.5133-.37.65L8.68 18c-.54.32-.82.32-1.06.18l-.25-.14c-.22281-.133-.36232-.3706-.37-.63V6.59005c.00081-.26656.14121-.51322.37-.65l.25-.14c.24-.14.52-.14 1.22.27L17.63 11.21c.2288.1368.3692.3835.37.65Z' fill='%23fff'/%3E%3C/svg%3E");
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 80%;
        background-size: 100% 100%;
        top: 10%;
        position: absolute;
    }

    #vr_player {
        width: 100%;
        height: 0;
        padding-top: 62.25%;
        position: relative;

        .vr_player_inner {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    #player_wrapper_outer {
        position: relative;
        max-width: 960px;
        margin: 0 auto;

        .views {
            position: absolute;
            bottom: 1.75rem;
            right: 1.25rem;
            z-index: 50;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: var(--video-views-bg-color-rgba);
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            color: var(--video-views-text-color);
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;

            svg {
                top: 0;
            }
        }

        .play_cover {
            position: relative;
            z-index: 50;
            width: 100%;
            cursor: wait;
            height: 0;
            padding: 56% 0 0 0;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.2);
            display: block;

            img.player_thumb {
                width: 100%;
                margin: 0 auto;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }

            .loading-icon img {
                height: 50px;
                display: block;
                margin: 15px auto 0 auto;
            }

            .loading-icon,
            .loading-icon::before,
            .loading-icon::after {
                left: 50%;
                top: 50%;
                margin-left: -40px;
                margin-top: -40px;
                position: absolute;
                vertical-align: middle;
                background: @back-d4;
                width: 80px;
                height: 80px;
                border-radius: 30%;
                border: 2px solid @back-d1;
            }

            .loading-icon::before {
                content: '';
                animation: bounce 1.5s infinite;
                animation-timing-function: ease-out;
            }
            .loading-icon::after {
                content: '';
                animation: bounce 1.5s -0.4s infinite;
                animation-timing-function: ease-out;
            }

            span.hd-time {
                position: absolute;
                top: 5px;
                left: 5px;
            }

            span.i-length,
            span.i-plays,
            span.i-date,
            span.i-watch,
            span.i-hd {
                height: 30px;
                position: absolute;
                top: 5px;
                left: 5px;
                padding: 0 5px;
                overflow: hidden;
                background-color: fade(@back-d4, 55);
                color: @text;
                font-size: 1em;
                text-align: center;
                line-height: 30px;
                .rounded(3px);
            }

            span.i-hd {
                position: static;
                background-color: fade(@back-d4, 80);
                display: inline-block;
                font-weight: bold;
                font-style: italic;
                margin-left: -2px;
            }

            span.i-length {
                position: static;
                background-color: fade(@back-d4, 75);
                display: inline-block;
            }

            span.i-plays {
                top: 38px;
            }

            span.i-date {
                top: 72px;
            }

            span.i-watch {
                top: auto;
                bottom: 5px;
                color: @front-d4;
                background-color: transparent;
                .opacity(1);
            }
        }

        #vast_player_container {
            display: none;
            width: 100%;
            padding-top: 56.25%;
            position: relative;

            video,
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            .advertisement {
                position: absolute;
                left: 2px;
                top: 2px;
                display: inline;
                padding: 5px;
                background-color: fade(@back-d4, 70);
                font-size: 0.7em;
                color: @text-l1;

                .i_svg {
                    fill: @front-l4;
                }
            }
            .skip_button {
                background-color: @front-d4;
                padding: 5px 10px 5px 2px;
                line-height: 33px;
                .border-radius(0, 0, 5px, 5px);
                font-size: 0.9em;
                .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 1px, @alpha: 0.6);
                .text-shadow(@x-axis: 0, @y-axis: 2px, @blur: 2px, @alpha: 1);
                .gradient(@color: @front-d4, @start: @front-d4, @stop: @middle-d4);
                bottom: 15px;
                position: absolute;
                right: 0;
                text-align: center;

                img {
                    float: left;
                    width: 55px;
                    margin-right: 4px;
                    .rounded(5px);
                    border: 1px solid @back;
                }

                a {
                    font-size: 1.1em;
                }
            }
        }
    }

    ul.positions {
        display: none;
    }

    .thumbnails {
        div {
            > span {
                display: inline-block;
                vertical-align: middle;
                position: relative;

                img {
                    max-width: 28vw;
                    min-width: 21vw;
                }

                > span {
                    position: absolute;
                    bottom: 3px;
                    right: 2px;
                    background-color: fade(@back-d4, 70);
                    display: inline-block;
                    padding: 3px;
                    color: @text;
                    .rounded(5px);
                    font-size: 0.8em;
                }
            }
        }

        figcaption {
            text-align: center;
            padding: 5px 0;
            color: @text;
        }
    }

    ul.video_toolbar {
        .flex-display(flex);
        .flex-wrap(nowrap);
        .align-content(stretch);
        .align-items(stretch);
        position: relative;
        gap: 0.25rem;

        li {
            .flex-shrink(0);
            .flex-display(flex);
            .align-items(center);
            .justify-content(center);
            .rounded(0.25rem);
            gap: 0.25rem;
            font-size: 1em;
            padding: 0.5rem 0.25rem;
            text-align: center;
            cursor: pointer;
            overflow: hidden;
            background-color: @video-toolbar-item-bg-color;
            color: @video-toolbar-item-color;

            &.rt {
                .flex-grow(1);
            }

            .i_svg {
                color: @video-toolbar-icon-color;
                padding: 0.125rem;
                width: 1.25rem;
                height: 1.25rem;
                top: 0;
                display: block;

                &.i_new-ui-chevron-right {
                    padding: 0.25rem;
                }

                &.i_new-ui-download-from-cloud {
                    padding: 0;
                }
            }

            a {
                color: inherit;
            }

            &.mod_delete {
                background-color: @front-d4;
                color: @text-l1;
                cursor: pointer;
            }

            &.active {
                svg {
                    fill: @front-l1;
                }
            }
        }
    }

    .user {
        .flex-display(flex);
        .flex-wrap(nowrap);
        .align-content(stretch);
        .align-items(center);
        padding: 0.5rem 1rem;
        gap: 0.5rem;
        background-color: @video-user-bg-color;
        color: @video-user-color;
        .rounded(0.25rem);

        .avatar {
            width: 1.5rem;
            height: 1.5rem;
            .rounded(100%);
            object-fit: cover;
            background-color: @video-avatar-default-bg-color;
        }

        > a,
        > span:not(.ft-button) {
            .flex-shrink(0);
            .flex-grow(0);
            color: inherit;

            &.name {
                .flex-grow(1);
                .flex-shrink(1);
                .flex-display();
                .align-items(center);
                gap: 0.25rem;
                overflow: hidden;

                .text {
                    color: @video-user-link-color;
                    font-weight: 700;
                    line-height: 1.5rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                }

                .i_svg {
                    .flex-shrink(0);
                    width: 1.5rem;
                    height: 1.5rem;
                    padding: 0.1875rem;
                    top: 0;

                    &.i_new-ui-chevron-right {
                        padding: 0.375rem;
                    }
                }
            }

            &.icon-link {
                padding: 0.5rem;
                width: 2.25rem;
                height: 2.25rem;

                .i_svg {
                    top: 0;
                    width: 100%;
                    height: 100%;

                    &.i_new-ui-account-circle {
                        padding: 0.0625rem;
                    }

                    &.i_new-ui-messages {
                        padding: 0;
                    }
                }
            }
        }
    }

    .searches {
        .tag-list(a);

        a {
            img {
                width: 12px;
                height: 12px;
                .rounded(3px);
            }
        }
    }

    h1 {
        font-size: 0.875rem;
        padding: 0;
        color: @title-color;
        font-weight: 700;
    }

    .text-block {
        background-color: transparent;
        padding: 5px;
        margin: 5px 0;
        color: @text;
        font-size: 0.8em;

        a {
            color: @front-l4;
            display: inline-block;
            padding: 0 4px;
            margin: 2px;
            text-decoration: underline;
            text-transform: capitalize;
        }
    }

    .preview_image_holder,
    .playlist_holder,
    .download_holder {
        display: none;
    }

    .messagebar {
        display: none;
    }
}

#thumbnails-remodal {
    figure.thumbnails {
        margin: 0;
        padding: 0;
        height: 90%;
        overflow: scroll;

        img {
            width: 48%;
            margin: 0.5%;
        }

        figcaption {
            text-align: center;
            padding: 5px 0;
            color: @text;
        }
    }
}

.download_holder_popup .b_unlock,
.download_holder_popup .b_720p,
.download_holder_popup .b_480p,
.download_holder_popup .b_240p {
    display: none;
}

@media all and (min-width: 375px) {
    #video {
        ul.video_toolbar {
            gap: 0.5rem;

            li {
                gap: 0.5rem;
                padding-left: 0.25rem;
                padding-right: 0.25rem;
            }
        }
    }
}

.more_videos {
    a {
        color: var(--primary-btn-color);
    }
}
