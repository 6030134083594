// main:style.less
#categories {

  a {
    display: inline-block;
    width: 32.2%;
    text-align: center;
    color: @text-l1;
    padding-bottom: 10px;
    font-weight: bold;

    img {
      display: block;
      margin: 0 auto;
      .rounded(5px);
      border: 1px solid @back-l4;
    }
  }
}

/* Portrait */
@media only screen
  and (orientation: landscape) {
    #categories {
      a {
        width: 19.2%;
      }
    }
}

@media only screen
  and (min-device-width: 768px) {
    #categories {
      a {
        width: 19.2%;
      }
    }
}
