// main:style.less

#player {
  .preloader {
    height: 20px;
    text-align: center;
  }

  .player_wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    max-width: 600px;
    margin: 0 auto;

    #video_player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }

  .thumbnails {

    div {
      height: 100px;
      overflow-x: scroll;
      overflow-y: hidden;
      width: auto;
      white-space: nowrap;

      a {
        display: inline-block;
        vertical-align: middle;
        border: 4px solid @back-d4;
      }
    }

  	figcaption {
  		text-align: center;
  		padding: 5px 0;
  		color: @text;
  	}
  }

  .share {
    margin: 5px;
    padding: 10px;
    background-color: @front-d4;
    .rounded(5px);
    text-align: center;
    color: @text-l1;
    border: 1px solid @front;
    font-size: 1.25em;

  }

  .download {
    text-align: center;
    padding: 5px;
    color: @text-d1;
    display: block;
  }
}

#share-remodal {

  h2 {
    color: #F76B1C;
    text-align: center;
    padding: 10px;
  }

  span {
    display: inline-block;
    background-color: #000;
    height: 50px;
    line-height: 50px;
    width: 46.5%;
    margin: 5px 1%;
    font-weight: bold;
    text-indent: 50px;
    color: #FFF;
    font-size: 1em;
    background-position: left center;
    background-repeat: no-repeat;
    .rounded(5px);
    .drop-shadow(@x-axis: 0, @y-axis: 2px, @blur: 2px, @alpha: 0.4);
    cursor: pointer;

    &.facebook {
        background-color: #3b5998;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M17.78%2027.5V17.008h3.522l.527-4.09h-4.05v-2.61c0-1.182.33-1.99%202.023-1.99h2.166V4.66c-.375-.05-1.66-.16-3.155-.16-3.123%200-5.26%201.905-5.26%205.405v3.016h-3.53v4.09h3.53V27.5h4.223z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.messenger {
      background-color: #0084ff;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M16%205C9.986%205%205.11%209.56%205.11%2015.182c0%203.2%201.58%206.054%204.046%207.92V27l3.716-2.06c.99.276%202.04.425%203.128.425%206.014%200%2010.89-4.56%2010.89-10.183S22.013%205%2016%205zm1.147%2013.655L14.33%2015.73l-5.423%203%205.946-6.31%202.816%202.925%205.42-3-5.946%206.31z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.sms {
      background-color: #6cbe45;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M16%203.543c-7.177%200-13%204.612-13%2010.294%200%203.35%202.027%206.33%205.16%208.21%201.71%201.565%201.542%204.08-.827%206.41%202.874%200%207.445-1.698%208.462-4.34H16c7.176%200%2013-4.605%2013-10.285s-5.824-10.29-13-10.29zM9.045%2017.376c-.73%200-1.45-.19-1.81-.388l.294-1.194c.384.2.98.398%201.6.398.66%200%201.01-.275%201.01-.692%200-.398-.302-.625-1.07-.9-1.06-.37-1.753-.957-1.753-1.886%200-1.09.91-1.924%202.415-1.924.72%200%201.25.152%201.63.322l-.322%201.166a3.037%203.037%200%200%200-1.336-.303c-.625%200-.93.284-.93.616%200%20.41.36.59%201.186.9%201.127.42%201.658%201.01%201.658%201.91.003%201.07-.822%201.98-2.575%201.98zm9.053-.095l-.095-2.44a72.993%2072.993%200%200%201-.057-2.626h-.028a35.41%2035.41%200%200%201-.71%202.475l-.778%202.49h-1.128l-.682-2.473a29.602%2029.602%200%200%201-.578-2.493h-.02c-.037.863-.065%201.85-.112%202.645l-.114%202.425H12.46l.407-6.386h1.924l.63%202.13c.2.74.397%201.536.54%202.285h.027a52.9%2052.9%200%200%201%20.607-2.293l.683-2.12h1.886l.35%206.386H18.1zm4.09.1c-.73%200-1.45-.19-1.81-.39l.293-1.194c.39.2.99.398%201.605.398.663%200%201.014-.275%201.014-.692%200-.396-.305-.623-1.07-.9-1.064-.37-1.755-.955-1.755-1.884%200-1.09.91-1.924%202.416-1.924.72%200%201.25.153%201.63.323l-.322%201.166a3.038%203.038%200%200%200-1.337-.303c-.625%200-.93.284-.93.616%200%20.408.36.588%201.186.9%201.127.42%201.658%201.006%201.658%201.906.002%201.07-.823%201.98-2.576%201.98z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.whatsapp {
      background-color: #12af0a;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20fill%3D%22%23FFF%22%20d%3D%22M16.21%204.41C9.973%204.41%204.917%209.465%204.917%2015.7c0%202.134.592%204.13%201.62%205.832L4.5%2027.59l6.25-2.002a11.241%2011.241%200%200%200%205.46%201.404c6.234%200%2011.29-5.055%2011.29-11.29%200-6.237-5.056-11.292-11.29-11.292zm0%2020.69c-1.91%200-3.69-.57-5.173-1.553l-3.61%201.156%201.173-3.49a9.345%209.345%200%200%201-1.79-5.512c0-5.18%204.217-9.4%209.4-9.4%205.183%200%209.397%204.22%209.397%209.4%200%205.188-4.214%209.4-9.398%209.4zm5.293-6.832c-.284-.155-1.673-.906-1.934-1.012-.265-.106-.455-.16-.658.12s-.78.91-.954%201.096c-.176.186-.345.203-.628.048-.282-.154-1.2-.494-2.264-1.517-.83-.795-1.373-1.76-1.53-2.055-.158-.295%200-.445.15-.584.134-.124.3-.326.45-.488.15-.163.203-.28.306-.47.104-.19.06-.36-.005-.506-.066-.147-.59-1.587-.81-2.173-.218-.586-.46-.498-.63-.505-.168-.007-.358-.038-.55-.045-.19-.007-.51.054-.78.332-.277.274-1.05.943-1.1%202.362-.055%201.418.926%202.826%201.064%203.023.137.2%201.874%203.272%204.76%204.537%202.888%201.264%202.9.878%203.43.85.53-.027%201.734-.633%202-1.297.266-.664.287-1.24.22-1.363-.07-.123-.26-.203-.54-.357z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.telegram {
      background-color: #2ca5e0;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M25.515%206.896L6.027%2014.41c-1.33.534-1.322%201.276-.243%201.606l5%201.56%201.72%205.66c.226.625.115.873.77.873.506%200%20.73-.235%201.012-.51l2.43-2.363%205.056%203.734c.93.514%201.602.25%201.834-.863l3.32-15.638c.338-1.363-.52-1.98-1.41-1.577z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.viber {
      background-color: #7c529e;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20d%3D%22M22.57%2027.22a7.39%207.39%200%200%201-1.14-.32%2029%2029%200%200%201-16-16.12c-1-2.55%200-4.7%202.66-5.58a2%202%200%200%201%201.39%200c1.12.41%203.94%204.3%204%205.46a2%202%200%200%201-1.16%201.78%202%202%200%200%200-.66%202.84A10.3%2010.3%200%200%200%2017%2020.55a1.67%201.67%200%200%200%202.35-.55c1.07-1.62%202.38-1.54%203.82-.54.72.51%201.45%201%202.14%201.55.93.75%202.1%201.37%201.55%202.94a5.21%205.21%200%200%201-4.29%203.27zM17.06%204.79A10.42%2010.42%200%200%201%2026.79%2015c0%20.51.18%201.27-.58%201.25s-.54-.78-.6-1.29c-.7-5.52-3.23-8.13-8.71-9-.45-.07-1.15%200-1.11-.57.05-.87.87-.54%201.27-.6z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3Cpath%20d%3D%22M24.09%2014.06c-.05.38.17%201-.45%201.13-.83.13-.67-.64-.75-1.13-.56-3.36-1.74-4.59-5.12-5.35-.5-.11-1.27%200-1.15-.8s.82-.48%201.35-.42a6.9%206.9%200%200%201%206.12%206.57z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3Cpath%20d%3D%22M21.52%2013.45c0%20.43%200%20.87-.53.93s-.6-.26-.64-.64a2.47%202.47%200%200%200-2.26-2.43c-.42-.07-.82-.2-.63-.76.13-.38.47-.41.83-.42a3.66%203.66%200%200%201%203.23%203.32z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')
    }

    &.line {
      background-color: #00c300;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M28%2014.304c0-5.37-5.384-9.738-12-9.738S4%208.936%204%2014.304c0%204.814%204.27%208.846%2010.035%209.608.39.084.923.258%201.058.592.122.303.08.778.04%201.084l-.172%201.028c-.05.303-.24%201.187%201.04.647s6.91-4.07%209.43-6.968c1.74-1.905%202.57-3.842%202.57-5.99zM11.302%2017.5H8.918a.631.631%200%200%201-.63-.63V12.1a.63.63%200%200%201%201.26.002v4.14h1.754c.35%200%20.63.28.63.628a.63.63%200%200%201-.63.63zm2.467-.63a.631.631%200%200%201-.63.628.629.629%200%200%201-.63-.63V12.1a.63.63%200%201%201%201.26%200v4.77zm5.74%200a.632.632%200%200%201-1.137.378l-2.443-3.33v2.95a.631.631%200%200%201-1.26%200V12.1a.634.634%200%200%201%20.63-.63.63.63%200%200%201%20.504.252l2.444%203.328V12.1a.63.63%200%200%201%201.26%200v4.77zm3.853-3.014a.63.63%200%201%201%200%201.258H21.61v1.126h1.755a.63.63%200%201%201%200%201.258H20.98a.63.63%200%200%201-.628-.63V12.1a.63.63%200%200%201%20.63-.628h2.384a.63.63%200%200%201%200%201.258h-1.754v1.126h1.754z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.skype {
      background-color: #00aff0;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M27.15%2018c-.007.04-.012.084-.02.126l-.04-.24.06.113c.124-.678.19-1.37.19-2.06%200-1.53-.3-3.013-.892-4.41a11.273%2011.273%200%200%200-2.43-3.602%2011.288%2011.288%200%200%200-8.012-3.32c-.72%200-1.443.068-2.146.203h-.005c.04.023.08.04.118.063l-.238-.037c.04-.01.08-.018.12-.026a6.717%206.717%200%200%200-3.146-.787%206.67%206.67%200%200%200-4.748%201.965A6.7%206.7%200%200%200%204%2010.738c0%201.14.293%202.262.844%203.253.007-.04.012-.08.02-.12l.04.238-.06-.114c-.112.643-.17%201.3-.17%201.954a11.285%2011.285%200%200%200%203.32%208.012c1.04%201.04%202.25%201.86%203.602%202.43%201.397.592%202.882.89%204.412.89.666%200%201.334-.06%201.985-.175-.038-.02-.077-.04-.116-.063l.242.04c-.046.01-.088.015-.13.02a6.68%206.68%200%200%200%203.3.87%206.661%206.661%200%200%200%204.743-1.963A6.666%206.666%200%200%200%2028%2021.26c0-1.145-.295-2.27-.85-3.264zm-11.098%204.885c-4.027%200-5.828-1.98-5.828-3.463%200-.76.562-1.294%201.336-1.294%201.723%200%201.277%202.474%204.49%202.474%201.647%200%202.556-.893%202.556-1.808%200-.55-.27-1.16-1.355-1.426l-3.58-.895c-2.88-.723-3.405-2.282-3.405-3.748%200-3.043%202.865-4.186%205.556-4.186%202.478%200%205.4%201.37%205.4%203.192%200%20.783-.677%201.237-1.45%201.237-1.472%200-1.2-2.035-4.163-2.035-1.47%200-2.285.666-2.285%201.618%200%20.95%201.16%201.254%202.17%201.484l2.65.587c2.905.647%203.64%202.342%203.64%203.94%200%202.47-1.895%204.318-5.726%204.318z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.wechat {
      background-color: #7bb32e;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cg%20fill%3D%22%23FFF%22%3E%3Cpath%20d%3D%22M20.674%2012.458c-2.228.116-4.165.792-5.738%202.318-1.59%201.542-2.315%203.43-2.116%205.772-.87-.108-1.664-.227-2.462-.294-.276-.023-.602.01-.836.14-.774.438-1.517.932-2.397%201.482.16-.73.266-1.37.45-1.985.137-.45.074-.7-.342-.994-2.673-1.89-3.803-4.714-2.958-7.624.78-2.69%202.697-4.323%205.302-5.173%203.555-1.16%207.55.022%209.712%202.845a6.632%206.632%200%200%201%201.38%203.516zm-10.253-.906c.025-.532-.44-1.01-.984-1.027a.997.997%200%200%200-1.038.964.984.984%200%200%200%20.977%201.02%201.017%201.017%200%200%200%201.05-.96zm5.35-1.028c-.55.01-1.01.478-1%201.012.01.554.466.987%201.03.98a.982.982%200%200%200%20.99-1.01.992.992%200%200%200-1.02-.982z%22%2F%3E%3Cpath%20d%3D%22M25.68%2026.347c-.705-.314-1.352-.785-2.042-.857-.686-.072-1.408.324-2.126.398-2.187.224-4.147-.386-5.762-1.88-3.073-2.842-2.634-7.2.92-9.53%203.16-2.07%207.795-1.38%2010.022%201.493%201.944%202.51%201.716%205.837-.658%207.94-.687.61-.934%201.11-.493%201.917.086.148.095.336.14.523zm-8.03-7.775c.448%200%20.818-.35.835-.795a.835.835%200%200%200-.83-.865.845.845%200%200%200-.84.86c.016.442.388.8.834.8zm5.176-1.658a.83.83%200%200%200-.824.794c-.02.47.347.858.813.86.45%200%20.807-.34.823-.79a.825.825%200%200%200-.812-.864z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')
    }

    &.twitter {
      background-color: #55acee;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M28%208.557a9.913%209.913%200%200%201-2.828.775%204.93%204.93%200%200%200%202.166-2.725%209.738%209.738%200%200%201-3.13%201.194%204.92%204.92%200%200%200-3.593-1.55%204.924%204.924%200%200%200-4.794%206.049c-4.09-.21-7.72-2.17-10.15-5.15a4.942%204.942%200%200%200-.665%202.477c0%201.71.87%203.214%202.19%204.1a4.968%204.968%200%200%201-2.23-.616v.06c0%202.39%201.7%204.38%203.952%204.83-.414.115-.85.174-1.297.174-.318%200-.626-.03-.928-.086a4.935%204.935%200%200%200%204.6%203.42%209.893%209.893%200%200%201-6.114%202.107c-.398%200-.79-.023-1.175-.068a13.953%2013.953%200%200%200%207.55%202.213c9.056%200%2014.01-7.507%2014.01-14.013%200-.213-.005-.426-.015-.637.96-.695%201.795-1.56%202.455-2.55z%22%2F%3E%3C%2Fsvg%3E')
    }

    &.reddit {
      background-color: #ff4500;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20d%3D%22M28.543%2015.774a2.953%202.953%200%200%200-2.951-2.949%202.882%202.882%200%200%200-1.9.713%2014.075%2014.075%200%200%200-6.85-2.044l1.38-4.349%203.768.884a2.452%202.452%200%201%200%20.24-1.176l-4.274-1a.6.6%200%200%200-.709.4l-1.659%205.224a14.314%2014.314%200%200%200-7.316%202.029%202.908%202.908%200%200%200-1.872-.681%202.942%202.942%200%200%200-1.618%205.4%205.109%205.109%200%200%200-.062.765c0%204.158%205.037%207.541%2011.229%207.541s11.22-3.383%2011.22-7.541a5.2%205.2%200%200%200-.053-.706%202.963%202.963%200%200%200%201.427-2.51zm-18.008%201.88a1.753%201.753%200%200%201%201.73-1.74%201.73%201.73%200%200%201%201.709%201.74%201.709%201.709%200%200%201-1.709%201.711%201.733%201.733%200%200%201-1.73-1.711zm9.565%204.968a5.573%205.573%200%200%201-4.081%201.272h-.032a5.576%205.576%200%200%201-4.087-1.272.6.6%200%200%201%20.844-.854%204.5%204.5%200%200%200%203.238.927h.032a4.5%204.5%200%200%200%203.237-.927.6.6%200%201%201%20.844.854zm-.331-3.256a1.726%201.726%200%201%201%201.709-1.712%201.717%201.717%200%200%201-1.712%201.712z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E')
    }

    &.pinterest {
      background-color: #bd081c;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20d%3D%22M16.56%204C10%204%206.71%208.7%206.71%2012.61c0%202.37.9%204.48%202.82%205.27a.47.47%200%200%200%20.69-.34l.28-1.11a.67.67%200%200%200-.2-.77A4%204%200%200%201%209.4%2013a6.53%206.53%200%200%201%206.79-6.6c3.7%200%205.74%202.26%205.74%205.28%200%204-1.76%207.33-4.37%207.33a2.13%202.13%200%200%201-2.18-2.66c.41-1.75%201.22-3.63%201.22-4.89a1.85%201.85%200%200%200-1.86-2.07c-1.47%200-2.66%201.53-2.66%203.57a5.31%205.31%200%200%200%20.44%202.18l-1.77%207.51a15.45%2015.45%200%200%200%200%205.24.18.18%200%200%200%20.33.08%2014.71%2014.71%200%200%200%202.49-4.51c.17-.61%201-3.79%201-3.79a4%204%200%200%200%203.37%201.72c4.43%200%207.44-4%207.44-9.45C25.29%207.81%2021.83%204%2016.56%204z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E')
    }

  }

}
