// main:style.less

#channel {
    .top {
        width: 90%;
        margin: 90px auto 5px auto;
        background-color: @back-d3;
        text-align: center;
        padding-bottom: 10px;
        .rounded(5px);

        .avatar {
            margin-top: -30px;
            width: 90px;
            .rounded(10px);
        }

        h1 {
            font-size: 1.5em;
            line-height: 2em;
            color: @front-l4;
            background-color: transparent;
        }

        .details {
            padding: 0 5px;
            color: @text-d1;
            font-weight: bold;

            span {
                color: @back-l4;
                font-weight: normal;
            }

            a {
                color: @front-l4;
            }
        }
    }
}

#search_v2.channel_page {
    .ads_placement {
        padding: 0;
    }

    p.desc {
        margin-top: 1.5rem;
        color: @channel-page-description-color;
        line-height: 1.25rem;
    }

    .ttaa2v3 {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}
