@import '../../common/styles/general';

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    -webkit-overflow-scrolling: touch;
}

html {
    background-color: @back-d3;
    overflow-x: hidden;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

body {
    color: @text;
    font-size: 13px;
    min-height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    display: block;
    opacity: 1;

    font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (500 - 300)));
}

@media screen and (min-width: 500px) {
    body {
        font-size: 14px;
    }
}

h1 {
    font-size: 1.4em;
    font-weight: normal;
    padding: 5px;
    color: @text-d1;

    em {
        color: @back-l4;
    }
}

h2 {
    font-size: 1.4em;
    font-weight: normal;
    padding: 15px 0;
    color: @text-d1;
}

h3 {
    font-size: 1.1em;
    font-weight: normal;
    padding: 8px 0;
    color: @front-l4;
}

hr {
    border-top: 1px solid @back;
}

.bio_description {
    max-height: 6.75rem;
    transition: 500ms max-height;
    font-size: 0.75rem;

    .further_details {
        display: flex;
        width: 100%;
        padding-top: 1rem;
        flex-direction: row;
        justify-content: space-between;

        .further_details_column {
            width: 46%;
            display: flex;
            flex-direction: column;

            span {
                margin-bottom: 0.3rem;
            }
        }
    }

    .further_details_invisible {
        display: none;
    }

    .further_details_visible {
        display: flex;
    }

    li {
        padding-top: 1rem;
        padding-right: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;

        &.i {
            position: relative;

            .toggle_info {
                position: absolute;
                right: 0;
                bottom: 0;

                .i_svg {
                    fill: @profile-icon-color !important;
                    transition: 250ms transform;
                }
            }
        }
    }

    &:not(.expanded) {
        .bio {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.expanded {
        transition: 500ms max-height;
        max-height: 100rem;
        white-space: normal;

        .toggle_info {
            .i_svg {
                transform: rotate(180deg);
            }
        }
    }
}

.ptgncdn_holder {
    margin: 1rem auto;
    .rounded(3px);
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.ptgncdn_holder_footer {
    margin-top: 10px;

    &.hidden {
        display: none;
    }
}

.ads_disclaimer {
    display: block;
    overflow: hidden;
    clear: both;
    color: @back-l4;
    text-align: center;
    padding: 3px 0;
}

.xplayer,
.xplayer * {
    z-index: 20000 !important;
}

.bottom_space {
    margin-bottom: 130px;
}

#inner_content {
    margin-top: 6.5625rem;
}

#svg_holder {
    display: none;
}

.ucbrowser_fail {
    padding: 5px;
    background-color: @front-d4;
    margin: 5px;
    color: @text-l1;
    text-align: center;
    display: none;
    margin-top: 150px;
}

html.ua-ucbrowser {
    .ucbrowser_fail {
        display: block;
    }
}

.header_link_ad {
    display: block;
    text-align: center;
    color: @front-l4;
    background-color: @back;
    padding: 5px;
    .rounded(5px);
    font-size: 1.25em;
    margin: -5px 5px 0 5px;
}

input.big-text-black {
    background-color: @back-d4;
    width: 100%;
    max-width: 400px;
    height: 40px;
    color: @front-l4;
    padding: 4px;
    font-size: 1.3em;

    .bordered(#222,#111,#111,#333);
    .drop-shadow();
}

p.buttons {
    padding: 5px 2px;
    display: table;
    width: 100%;
    table-layout: fixed;

    a {
        display: table-cell;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: @text;
        font-size: 1.15em;
        width: 1%;
        border: 1px solid @back-d4;
        border-right: 0;

        &:first-child {
            .border-radius(0,0,5px,5px);
        }
        &:last-child {
            .border-radius(5px,5px,0,0);
            border-right: 1px solid @back-d4;
        }

        &:hover,
        &:active,
        &.active {
            .gradient(@color: @front-d4, @start: @front-d4, @stop: @middle-d4);
        }

        .i_svg {
            fill: @front-l4;
        }
    }
}

.button {
    display: block;
    margin: 5px auto;
    height: 30px;
    line-height: 30px;
    width: 33%;
    text-align: center;
    color: @front-l4 !important;
    border: 1px solid @front-d4;
    background-color: @back-d4;
    .rounded(5px);
    font-size: 1em;
    text-decoration: none !important;
    width: 100%;

    &:hover,
    &:active {
        background-color: @front-d4;
    }

    &.active {
        background-color: @front-d4;
    }
}

.lists {
    padding: 5px 0;
    .item {
        display: block;
        color: @text;
        padding: 8px;
        font-size: 1em;
        border-bottom: 1px solid @back-d3;
        border-top: 1px solid @back-d2;
        position: relative;
        text-align: left;
        overflow: hidden;
        clear: both;

        .i_svg.i_chevron-right {
            position: absolute;
            right: 5px;
            top: 8px;
        }

        img.icon {
            position: absolute;
            right: 5px;
            top: 7px;
            width: 24px;
        }

        img.cover {
            float: left;
            margin-right: 10px;
        }

        span.filter {
            display: block;
            padding: 5px 10px;
            background-color: @front-d4;
            color: @text-l1;
            position: absolute;
            right: 5px;
            top: 8px;
            letter-spacing: 1px;
            .rounded(5px);
        }

        .badge {
            position: absolute;
            right: 25px;
            top: 8px;
            color: @front;
        }

        .date {
            color: @back-l4;
            font-size: 0.8em;
        }

        .text {
            color: @front-l4;
            font-size: 0.9em;
        }

        h1 {
            font-size: 1em;
            background-color: transparent;
            display: inline-block;
        }

        &.active {
            background-color: @back-d4;
        }

        &.active-alt {
            background-color: @front-d4;
        }
    }
}

.playlist-video-list {
    height: 500px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
}

div.scroll_list {
    margin-right: -1rem;
    margin-left: -1rem;
    height: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    width: auto;
    white-space: nowrap;
    .remove-scroll-indicator();

    a,
    span {
        &:first-child {
            margin-left: 1rem;
        }

        &:last-child {
            margin-right: 1rem;
        }
    }

    a {
        display: inline-block;
        vertical-align: middle;
        color: var(--trending-title-color);
        font-size: 0.75rem;
        overflow: hidden;
        margin-right: 1rem;

        div.c {
            overflow: hidden;
            margin-bottom: 0.25rem;

            img {
                .rounded(0.25rem);
                width: 5rem;
                object-fit: cover;
            }
        }

        &.nrw {
            width: 3.125rem;
        }
    }

    .centered {
        text-align: center;
    }

    &.trending-pornstar {
        a {
            margin-right: 0.5rem;

            div.c {
                width: 6rem;

                img {
                    width: 100%;
                }
            }
        }
    }

    &.trending_categories {
        a {
            margin-right: 0.5rem;
        }
    }
}

#bottom-sheet {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(@bottom-sheet-overlay-bg-color, 0.8);
    backdrop-filter: blur(1.25rem);
    display: block;
    transition: opacity 200ms ease;
    .opacity(@opacity: 0);
    z-index: 0;
    pointer-events: none;

    .sheet {
        position: fixed;
        bottom: -500px;
        left: 0;
        right: 0;
        transition: bottom 300ms ease;
        width: 100%;
        padding-top: 1rem;

        .t {
            color: @bottom-sheet-title-color;
            font-size: 1.125rem;
            padding-left: 1rem;
            padding-right: 1rem;

            .i_svg {
                color: @bottom-sheet-title-icon-color;
                margin-right: 0.5rem;
            }
        }

        .c {
            max-height: 70vh;
            overflow: auto;
            background-color: @bottom-sheet-bg-color;
            margin-top: 1rem;

            a {
                display: block;
                padding: 1rem 1rem;
                font-size: 1.2em;
                color: @bottom-sheet-color;
                border-bottom: 1px solid @bottom-sheet-divider-color;
                text-transform: capitalize;

                &:last-child {
                    border-bottom: none;
                }

                .i_svg {
                    margin-right: 0.5rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    color: @bottom-sheet-icon-color;
                    top: 0.25rem;
                }
            }
        }
    }

    &.show {
        display: block;
        .opacity(@opacity: 1);
        pointer-events: all;
        z-index: 1000;

        .sheet {
            bottom: 0;
        }
    }
}

#interstitial_div_v3 {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: @back-d3;
    z-index: 10000;
    font-size: 12px;

    .top {
        height: 7vh;
        width: 100vw;
        padding: 0.5vh;
        line-height: 7vh;

        img {
            height: 6vh;
            float: left;
            display: block;
        }

        em {
            height: 6vh;
            float: left;
            display: block;
            line-height: 6vh;
            padding-left: 5px;
            font-style: normal;
            text-transform: uppercase;
            color: @back-l4;
        }

        span {
            float: right;
            height: 6vh;
            line-height: 6vh;
            width: 30vw;
            text-align: center;
            background-color: @front-d3;
            font-size: 1.3em;
            cursor: pointer;
            text-shadow: 0px 1px 0px #854629;
            text-transform: uppercase;
            font-weight: bold;
            .rounded(3px);

            svg {
                fill: @front-l4;
            }
        }
    }

    iframe {
        height: 93vh;
        width: 98vw;
        background-color: @back-d3;
        border: 0;
        display: block;
        background: url(/static/mobile/Images/preloader.gif) center center no-repeat;
    }
}

#dab_5151_ab {
    height: 1px;
    width: 1px;
    position: absolute;
    left: -999em;
    top: -999em;
}

#vast_tester {
    height: 70vh;

    .controls {
        margin: 20px;

        .vast_url {
            height: 35px;
            line-height: 35px;
            width: 80vw;
            display: block;
            margin-bottom: 10px;
            background-color: @text-d1;
            border: 2px solid @back-d4;
        }

        button {
            padding: 10px;
            cursor: pointer;
        }
    }

    #video_container {
        width: 90vw !important;
        height: 50vh;
    }

    #vast_video_player {
        width: 350px !important;
        height: 230px;
    }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 5px;
}

::-webkit-scrollbar:horizontal {
    height: 1px;
}

::-webkit-scrollbar-thumb {
    background-color: @back-d1;
    border-radius: 10px;
    border: 0 none;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

#home {
    .home_skin {
        margin-top: 10px;
        margin-bottom: -5px;
        margin-left: -5px;
        margin-right: -5px;
        text-align: center;

        ins {
            width: 100% !important;
            max-width: 500px;
        }
    }

    .more_videos {
        display: block;
        margin: 0 15px 5px 15px;

        a {
            color: var(--primary-btn-color);
        }
    }
}

.text-block {
    background-color: @back-d4;
    padding: 5px;
    margin: 5px 0;
    color: @text-d1;
    font-size: 1.1em;

    a {
        color: @front-l4;
        font-size: 1.05em;
        line-height: 2em;
    }
}

.messagebar {
    background-color: @messagebar-bg-color;
    padding: 0.5rem;
    position: relative;
    height: 80px;
    display: none;
    gap: 8px;

    textarea {
        .flex-grow(1);
        background-color: @messagebar-textarea-bg-color;
        color: @messagebar-textarea-text-color;
        padding: 0.5rem;
        border: none;
        .rounded(0.25rem);
    }

    a.link {
        .flex-shrink(0);
        background-image: linear-gradient(var(--misc-btn-bg-from-color), var(--misc-btn-bg-to-color));
        color: var(--misc-btn-color);
        padding: 0.5rem 1rem;
        .flex-display();
        .justify-content(center);
        .align-items(center);
        .rounded(0.25rem);
    }
}

ul.dropdown_list {
    text-align: left;

    #time_slider {
        margin: 10px;
    }

    #time_slider_value {
        height: 25px;
        li {
            display: inline-block;
            font-size: 1.25em;
            color: @back-l4;
            width: 50px;

            &.min {
                float: left;
                padding-left: 5px;
            }

            &.max {
                float: right;
                margin-right: -8px;
            }
        }
    }

    > li {
        width: 100%;
        position: relative;

        &.bt {
            margin-top: 20px;
        }

        ul.holder {
            padding: 2px 0;

            li {
                display: inline-block;
                padding: 5px;
                margin: 2px 0;

                &.title {
                    width: 25%;
                    margin-right: 2%;
                    color: @text;
                }

                &.selector {
                    width: 70%;
                    color: @front;
                    border-bottom: 1px solid @front-d4;
                    background-color: @back-d4;
                    .rounded(5px);

                    span {
                        color: @front-l4;
                    }

                    .i_svg {
                        float: right;
                        padding-top: 2px;
                    }
                }
            }
        }

        ul.form {
            display: none;
        }

        ul.manual {
            display: none;
        }

        select {
            position: absolute;
            top: 0px;
            left: 28%;
            height: 30px;
            width: 70%;
            .opacity(0.01);
        }

        ul.related {
            li {
                display: inline-block;

                a {
                    display: block;
                    padding: 5px 5px;
                    margin: 2px;
                    color: @front-l4;
                    background: @back-d4;
                    .rounded(5px);
                }
            }
        }
    }
}

.medal_hoder {
    span {
        display: inline-block;
        position: relative;
        padding-left: 16px;
        font-size: 0.95em;

        &.m1 {
            color: @front-l4;
        }

        &.m2 {
            color: @text;
        }

        &.m3,
        &.m4 {
            color: #daa736;
        }

        &.ms {
            padding: 2px 4px;
            background-color: @back-d3;
            .rounded(5px);
            color: @text;
            .i_svg {
                fill: @front-l2;
            }
        }

        img {
            position: absolute;
            width: 16px;
            left: 0;
        }
    }
}

.survey {
    display: block;
    color: @front-l4;
    text-align: center;
    padding: 10px;
}

.text_link_ads_adultforce {
    padding: 10px 5px;
    margin: 5px;
    background-color: @front-d4;
    text-align: center;
    color: @text-l1;
    display: block;
    .rounded(10px);
    font-size: 1em;

    span {
        font-weight: 800;
    }
}

.ads_placement {
    display: block;
    text-align: center;
    padding: 5px 0;

    span {
        display: inline-block;
        overflow: hidden;
        .rounded(0.25rem);
    }
}

.new-age-verification {
    p {
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: left;
    }
}

@keyframes bounce {
    0% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 0.8;
    }
    100% {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 0.8;
    }
    100% {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}

/* Icon pulse */
.i_fade {
    display: inline-block;
    -moz-animation: pulse 2s infinite linear;
    -o-animation: pulse 2s infinite linear;
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flash {
    0%,
    100%,
    50% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash {
    0%,
    100%,
    50% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.loader {
    display: inline-block;
    font-size: 10px;
    text-indent: -9999em;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background: transparent;
    background: -moz-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: -webkit-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: -o-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: -ms-linear-gradient(left, @front-d4 10%, fade(@front-d4, 0) 42%);
    background: linear-gradient(to right, @front-d4 10%, fade(@front-d4, 0) 42%);
    position: relative;
    -webkit-animation: load3 0.7s infinite linear;
    animation: load3 0.7s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: @front-d4;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: @back-d3;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
