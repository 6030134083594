// main:style.less

html.remodal_lock,
body.remodal_lock {
    overflow: hidden;
}

/* Anti FOUC */

.remodal,
[data-remodal-id] {
    visibility: hidden;
}

/* Overlay necessary styles */

.remodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;

    display: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    text-align: center;
}

/* Fix iPad, iPhone glitches */

.remodal-overlay > * {
    -webkit-transform: translateZ(0px);
}

/* Modal dialog necessary styles */

.remodal {
    position: relative;

    display: inline-block;
}

/* ==========================================================================
   Remodal default theme
   ========================================================================== */

/* Default theme font */

/* Overlay default theme styles */

.remodal-overlay {
    opacity: 0;
    background: rgba(var(--modal-background-color), 0.95);
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

body.remodal_active .remodal-overlay {
    opacity: 1;
}

/* Modal dialog default theme styles */

.remodal {
    width: 100%;
    min-height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    background: @back-d3;
    background-clip: padding-box;
    color: @text;
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-transition: -webkit-transform 0.2s linear;
    -moz-transition: -moz-transform 0.2s linear;
    -o-transition: -o-transform 0.2s linear;
    transition: transform 0.2s linear;
    border: 1px solid @back-d2;
    overflow: scroll;
}

body.remodal_active .remodal {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

/* Modal dialog vertical align  */

.remodal {
    vertical-align: middle;
}

/* Close button */

.remodal-close {
    position: absolute;
    top: 5px;
    right: 5px;

    width: 28px;
    height: 28px;
    line-height: 23px;

    text-decoration: none;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    font-size: 28px;
    color: @front;
    text-align: center;
}

.remodal-close:hover,
.remodal-close:active {
    color: @front;
}

/* Dialog buttons */

.remodal-confirm,
.remodal-cancel {
    display: inline-block;
    width: 120px;
    padding: 9px 0;
    margin: 0 0 5px 0;

    font-size: 10pt;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    background-clip: padding-box;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.remodal-confirm {
    border: 2px solid #16a085;
    background: #f4f4f4;
    color: #16a085;
}

.remodal-confirm:hover,
.remodal-confirm:active {
    background: #16a085;
    color: #f4f4f4;
}

.remodal-cancel {
    border: 2px solid #c0392b;
    background: #f4f4f4;
    color: #c0392b;
}

.remodal-cancel:hover,
.remodal-cancel:active {
    background: #c0392b;
    color: #f4f4f4;
}

/* Media queries
   ========================================================================== */

@media only screen and (min-width: 641px) {
    .remodal {
        max-width: 700px;
        margin: 0 auto;
        min-height: 0;
    }
}
